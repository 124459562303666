// 建议所有项目自定义样式放在该文件夹下
body {
    padding-right: 0 !important;
}

html,
body,
app-root {
    height: auto;
}

html,
body {
    height: 100%;
}

.wrapper {
    background: #E8ECF0;
}

// .ant-badge-status-text{
//   font-size: 12px;
// }
._btn-small,
.ant-btn-sm {
    font-size: 12px;
}
#_market{
    width: 300px;
    height:32px;
    border: 1px solid #d9d9d9;
    display: block;
    line-height: 32px; 
    padding-left: 5px;
    margin-right: 10px;
}

.details_table {
    width: 100%;
    min-height: 25px;
    line-height: 25px;
    text-align: left;
}

.details_table tr {
    border-bottom: 1px solid #eee;
    border-collapse: collapse;
    line-height: 40px;
    td:first-child {
        color: #8493A4;
    }
    td:last-child {
        color: #35485F;
    }
}

ul.ips_css {
    padding-left: 0;
}

ul.ips_css li:first-child {
    margin-top: 10px;
}

ul.ips_css li {
    line-height: 25px;
    height: 25px;
    list-style: none;
}

.monitor_Alert tr>td:first-child {
    padding-left: 15%;
}

.ipt_num_width {
    width: 120px;
}

.consoleActive{
    pointer-events: none;
    cursor: default;
    background: transparent !important;
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
}

.monitor_select {
    width: 100px;
    margin-left: 5px;
}

.monitor_btn_event {
    margin-left: 20px;
}

.func_button button[disabled],
.func_button button,
.func_button button[disabled]:hover,
.func_button button:hover {
    width: 100%;
    background: none;
    margin: 0;
    border: 0;
}

.ad-rt.fixed {
    top: 60px;
}

// 侧边栏
.aside {
    margin-top: 50px;
    background: #293541;
}

.sidebar-nav__group-title {
    color: #fff;
}

// .sidebar-nav__item{
//   width: 180px;
//   margin: 0 auto;
// }

.fontSize12 h5{
  font-size: 14px;
}
.fontSize12>div{
  font-size: 12px;
  line-height: 22px;
}

.sidebar-nav__item>a {
    font-size: 12px;
    color: #fff;
}

.sidebar-nav.sidebar-nav__sub {
    background: #34404c;
}

.sidebar-nav__selected>a {
    font-size: 12px;
    color: #1890ff;
}

.sidebar-nav__item.sidebar-nav__selected>a::before {
    background: #1890ff !important;
}

.sidebar-nav__selected {
    background: #34404c;
    border-radius: 4px;
}

.sidebar-nav__item>a>span {
    font-size: 14px;
    color: #fff;
}

.ad-nav__depth1 .ad-nav__item>a {
    padding-left: 30px;
}

.ad-nav__depth1 .ad-nav__item>a::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    margin-right: 8px;
    margin-bottom: 3px;
}

.ad-nav__depth1 .ad-nav__item>a:hover::before {
    background: #1890ff;
}

.login-label label {
    color: #6E6E6E;
}

.steps-content {
    .ant-form-explain div {
        text-align: left;
    }
}

.register_steps {
    width: 640px;
    transform: translateX(-50%);
    margin: 0 auto;
    border-radius: 6px 6px 0 0;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
    margin-left: 50%;
    padding: 45px;
}

.aside-inner {
    position: relative;
    .sidebar-custom {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 39px;
        width: 100%;
        div {
            display: flex;
            .custom-text {
                justify-content: center;
                line-height: 39px;
                width: 140px;
                font-size: 12px;
                color: #fff;
                align-items: center;
                letter-spacing: 0;
                i {
                    line-height: 39px;
                }
            }
            .custom-icon {
                flex: 1;
                justify-content: center;
                line-height: 39px;
                color: #fff;
                i {
                    line-height: 39px;
                }
            }
        }
    }
}

.top-nav .item,
.top-nav nz-badge {
    color: #35485f !important;
}
@media screen and (max-width: 414px) {
    .register_steps{
      // position: absolute;
      width: 90%;
      transform: translateX(0);
      margin: 0 auto;
      background: #fff;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
      border-radius: 6px 6px 0 0;
      margin-top: 60px;
      padding: 10px;
      .ant-steps-item-title{
          font-size: 14px;
      }
      .ant-steps-item-icon{
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
      }
      .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon{
          margin-right: 12px;
      }
      .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item{
          display: inline-block;
      }
      .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{
          margin-right: 8px;
          padding-top: 10px;
      }
      .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title{
          line-height: 25px;
      }
      .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-tail{
          display: none;
      }
    }
}
// header
.header {
    background: #fff;
    height: 50px;
    padding: 0;
    .logo {
        margin-left: -1px;
        background: #34404c;
        height: 50px;
        line-height: 50px;
    }
    .top-nav-wrap {
        height: 50px;
        .left-nav {
            color: #35485f;
            li {
                // font-family: SourceHanSansCN-Normal;
                font-size: 14px;
                color: #35485f !important;
                letter-spacing: 0;
                width: 152px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
                &:hover {
                    background: #fbfbfb;
                }
            }
        }
        .right-nav {
            li {
                height: 40px;
                line-height: 40px;
            }
            li.right-item {
                display: flex;
                div {
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    background: #dbdbdb;
                    margin: 11px;
                }
            }
        }
    }
}

// @media (min-width: 768px){
//     .alain-default__fixed .reuse-tab + router-outlet {
//         display: block;
//         height: 11px;
//     }
// }
@media (min-width: 769px) {
    .alain-default__collapsed .content {
        margin-left: 69px;
    }
    .content {
        margin-left: 205px;
    }
}

// user
.logout {
    text-align: center;
}

.user-header {
    padding: 15px;
    display: flex;
}

.header-name {
    display: flex;
}

.header-name-auth {
    font-size: 12px;
    color: #9e9e9e;
    margin: 0;
    margin-left: 19px;
}

.header-name-auth::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-bottom: 4px;
    border-radius: 50%;
    margin-right: 4px;
    background: #ff3e55;
}

.header-name-user {
    font-size: 12px;
    color: #35485f;
    margin: 0
}

.safe {
    font-size: 12px;
    color: #9e9e9e;
}

.personal-center {
    display: flex;
    flex-wrap: wrap;
}

.personal-center-item {
    text-align: center;
    font-size: 12px;
    width: 33.3%;
}

.personal-center-item div {
    word-wrap: break-word;
    word-break: break-all;
    width: 100%; // border:1px solid red;
}

.networkIP {
    margin-top: 10px;
    line-height: 32px;
}

.network.textright {
    text-align: right;
    padding-right: 20px;
}

.productImages {
    min-width: 60px;
    .ant-btn+.ant-btn {
        margin-left: 0;
    }
}

.productImages button,
.cloudList .operationBtn {
    padding: 1px 5px;
    margin: 0 2px;
}

.cloudList .operationBtn {
    .ant-btn+.ant-btn {
        margin-left: 0;
    }
}

.productImages button:first-child {
    padding: 0;
    border: 0;
    background: none;
}

.ipsList {
    margin-bottom: 0;
    padding-left: 0;
}

.ipsList li {
    list-style: none;
}

.ipsList+p i {
    transition: .3s;
    font-size: 14px;
    cursor: pointer;
}

.ip_show_rotate {
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -moz-transform: rotate(180deg);
    /* Firefox */
    -webkit-transform: rotate(180deg);
    /* Safari å’Œ Chrome */
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.ipsList+p {
    text-align: center;
    margin-bottom: 0;
}

.networkStyle {
    color: #40a9ff;
}

#bandwidth_slider,
#buy_duration_slider,
#buy_duration_sliders,
#disk_size,
#band_width,
#client_disk_size,
#client_bandwidth,
#buy_time {
    .ant-slider-handle {
        width: 19px;
        height: 34px;
        background: #fff;
        border: 1px solid #e5e5e5;
        color: white;
        border-radius: 0;
        overflow: hidden;
        padding: 0;
        top: -10px;
        cursor: pointer;
        box-shadow: none;
        z-index: 2;
        margin: 0 !important;
        margin-left: -1px !important;
    }
    .ant-slider-handle::before {
        content: "|";
        position: absolute;
        top: 5px;
        left: 4px;
        color: #e5e5e5;
    }
    .ant-slider-handle::after {
        content: "|";
        position: absolute;
        top: 5px;
        left: 9px;
        color: #e5e5e5;
    }
    .ant-slider-track {
        background: #EDF4FB;
        border: 1px solid #4A90E2;
        border-radius: 0;
        margin-top: -12px;
        height: 30px;
        box-shadow: none;
        overflow: hidden;
    }
    .ant-slider-rail {
        height: 30px;
        margin-top: -12px;
        border: 1px solid #ddd;
        background: none;
    }
    .ant-slider-mark {
        top: 0;
        left: -50px;
        .ant-slider-mark-text {
            margin-left: 0;
            top: -4px;
            text-align: center;
        }
        .ant-slider-mark-text-active {
            color: #4A90E2;
        }
    }
    .ant-slider-step {
        .ant-slider-dot {
            top: -11px;
            width: 1px;
            height: 28px;
            border: 0;
            margin-left: 0;
            border-right: 1px solid #e8e8e8;
        }
        .ant-slider-dot:last-child {
            border: 0;
        }
        .ant-slider-dot-active {
            border-right: 1px solid #4A90E2;
        }
    }
}

#buy_duration_slider .ant-slider-mark {
    left: -50%;
    transform: translateX(44%);
}

#buy_duration_sliders .ant-slider-mark {
    left: -68%;
    transform: translateX(44%);
}

.ant-modal {
    top: 45px;
}

.bandwidthMargin,
.bandwidthConfigs {
    .ant-modal-footer {
        border: 0;
        padding: 0;
    }
    .ant-modal-body,
    .ant-card-body {
        padding: 0;
    }
    .ant-table-wrapper,
    .ant-form,
    .noResize {
        padding: 15px;
    }
    .ant-form {
        padding-bottom: 0;
    }
    .ant-row.ant-form-item {
        padding-bottom: 0;
        margin-top: 15px;
        margin-bottom: 0;
    }
    .paysPadding {
        button {
            height: 50px;
            line-height: 50px;
            width: 180px;
            border-radius: 0;
        }
    }
}

.payInfos {
    border: 0;
    margin-top: 10px;
    border-radius: 0 0 4px 4px;
    border-top: 6px solid #999;
    p {
        height: 50px;
        line-height: 50px;
    }
    span {
        font-family: DINAlternate-Bold;
        font-size: 25px;
    }
    button {
        span {
            font-size: 14px;
        }
    }
    strong {
        margin-left: 30px;
    }
    b {
        font-family: DINAlternate-Bold;
    }
    p.shouldPay {
        padding-left: 24px;
        margin-bottom: 0;
        height: 80px;
        // line-height: 80px;
    }
    .paysPadding {
        height: 50px;
        line-height: 50px;
        background: #eef4fb;
        padding-left: 24px;
    }
}

.showInfos {
    span {
        font-family: DINAlternate-Bold;
        font-size: 25px;
    }
}

.noResize {
    margin-bottom: 0;
    span {
        color: #F5A623;
    }
}

.renewInfos {
    .ant-form-item {
        margin-bottom: 16px;
    }
}

#invoice {
    .invoiceLabel {
        width: 270px;
        height: 165px;
        position: relative;
        display: inline-block;
        margin: 5px;
        padding: 7px 40px;
        p {
            line-height: 24px;
            height: 24px;
            font-size: 14px;
            span {
                font-family: DINAlternate-Bold;
                font-size: 14px;
            }
        }
        .editInfo {
            position: absolute;
            right: 20px;
            top: 10px;
        }
        .editInfo:hover {
            color: #1890ff;
        }
    }
    .ant-radio-button-wrapper-checked,
    .ant-radio-button-wrapper:hover,
    .ant-radio-button-wrapper-focused {
        color: rgba(0, 0, 0, 0.65);
    }
    .ant-radio-button-wrapper-checked {
        background: #EDF4FB;
    }
}

.flex {
    display: flex;
    justify-content: space-between;
}

.cloudServer {
    display: flex;
    justify-content: space-between;
    li {
        list-style: none;
    }
    .cloudServer-left {
        margin-right: 290px;
        border: 1px solid #e1e3e4;
        flex: 1;
        width: 100%;
        background: white;
        .bandwidthMargin {
            border-bottom: 1px solid #e1e3e4;
            padding-bottom: 30px;
            margin: 10px 10px;
        }
        .bandwidthMargin:last-child {
            border-bottom: none;
        }
        #cpu,
        #memory,
        #area,
        #data_Center,
        #OS,
        #pay_information,
        #disk_type,
        #isp,
        #unit_price,
        #invoice_information,
        #need_invoice,
        #network_type,
        #client_cpu,
        #client_memory,
        #client_disk_type,
        #client_isp,
        #enhance {
            label {
                min-width: 90px;
                line-height: 30px;
                height: 30px;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                text-decoration: none;
                padding: 0 5px;
                font-size: 12px;
                border-radius: 0
            }
        }
        .ant-form-item-label label {
            font-family: SourceHanSansCN-Normal;
            font-size: 12px;
            color: #8493A4;
            letter-spacing: 0;
        }
        .ant-form .ant-form-item:first-child .ant-form-item-label label {
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #35485F;
            letter-spacing: 0;
        }
        .ant-form .ant-form-item:first-child .ant-form-item-label label:after {
            content: '';
        }
        .os-select.ant-form-item-label label:after {
            content: "";
        }
        .ant-radio-button-wrapper-checked {
            background: #EDF4FB;
            color: #4A90E2;
        }
        .ant-card-bordered {
            margin-bottom: 0;
        }
    }
    .cloudServer-right {
        position: fixed;
        top: 60px;
        right: 0;
        background: #fff;
        border: 1px solid #e1e3e4;
        width: 280px;
        .selected—config {
            padding: 0 20px;
            background: #F6F7FB;
            height: 70px;
            line-height: 70px;
            span {
                font-family: SourceHanSansCN-Medium;
                font-size: 16px;
                color: #35485F;
                letter-spacing: 0;
            }
            a {
                font-family: SourceHanSansCN-Normal;
                font-size: 12px;
                color: #4A90E2;
                letter-spacing: 0;
            }
        }
        .config-item {
            font-family: SourceHanSansCN-Normal;
            font-size: 12px;
            color: #35485F;
            letter-spacing: 0;
            overflow-y: auto;
            max-height: 350px;
            ul {
                padding: 10px 20px 20px 20px;
                margin: 0;
                li {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 5px;
                }
            }
        }
        .config-price {
            width: 238px;
            margin: 0 auto;
            padding-top: 20px;
            border-top: 1px solid #e1e3e4;
            .price-num {
                line-height: 32px;
            }
            .price-all {
                line-height: 32px;
                margin-top: 10px;
                .price-total {
                    font-family: DINAlternate-Bold;
                    font-size: 24px;
                    color: #35485F;
                }
                .price-icon {
                    font-family: DINCondensed-Bold;
                    font-size: 16px;
                    color: #35485F;
                }
            }
        }
        .config-agree {
            padding-left: 20px;
            margin: 15px 0;
        }
        button {
            width: 100%;
            border-radius: 0;
        }
    }
}

.renewConfirm .ant-card-body {
    padding: 0;
    padding-top: 15px;
    .confirmBtn {
        height: 50px;
        line-height: 43px;
        // padding: 0 35px;
        width: 100%;
        border-radius: 0;
    }
    .ant-input,
    .ant-input-affix-wrapper {
        width: 200px;
        margin: 0 8px 8px 0;
    }
    .coupon {
        margin-bottom: 13px;
        margin-top: -20px;
        .iptGoup {
            margin-left: 20px;
        }
        .iptBtn {
            font-size: 14px;
        }
    }
}

.renewConfirm {
    .ant-tag {
        line-height: 32px;
        height: 32px;
        font-family: DINAlternate-Bold;
        border: 0;
        color: #fff;
        background: url('../assets/images/coupon.png') no-repeat;
        background-size: 100% 100%;
        b {
            font-size: 18px;
        }
        .anticon-cross {
            color: #fff;
            font-size: 14px;
        }
    }
}

.listCard {
    position: relative;
    margin-top: -1px;
    .ant-card-body {
        padding: 24px 1px;
    }
}

.searchStyle {
    float: right;
    margin-top: -10px;
}

.defaultFunctionHeight {
    padding-right: 10px;
    margin-left: 8px;
    height: 59px;
    line-height: 59px;
    position: fixed;
    top: 60px;
    // background: #fff;
    z-index: 2;
}

.showFunction {
    padding-right: 10px;
    margin-top: 6px;
    margin-left: 0;
    .ant-card-body {
        padding: 13px 12px;
    }
}

@media (max-width: 1275px) {
    .searchStyle.cloud_searchs,
    .searchStyle.cloudVoumecss {
        margin-top: 36px;
        width: 100%;
    }
    .listCard {
        .defaultFunctionHeight {
            top: 59px;
            height: 51px;
            line-height: 51px;
            position: fixed;
        }
        .ant-input {
            font-size: 12px;
            height: 26px;
        }
        .ant-btn {
            padding: 0 14px;
            font-size: 12px;
            height: 26px;
        }
    }
    .radioFontSize>label {
        font-size: 12px;
    }
    .ssl_tables table .ant-btn {
        margin: 2px;
    }
}

.operationBtn {
    position: relative;
    .consoleitem2 {
        color: #ee660c;
        bottom: 0;
    }
    .consoleitem1 {
        font-size: 14px;
        top: 5px;
        left: 7px;
    }
    .consoleitem1,
    .consoleitem2 {
        position: absolute;
    }
}

.cardTabNav {
    .ant-tabs-nav-wrap {
        line-height: 2;
        background: #fff;
    }
    .sharePadding0 {
        .ant-card-body {
            padding: 0;
        }
        .ant-table-tbody>tr>td {
            border-bottom: 1px dashed #e8e8e8;
        }
    }
    .ant-tabs-nav-container {
        border: 1px solid #e8e8e8;
    }
    .ant-tabs-bar {
        margin-bottom: 5px;
    }
}

nz-card {
    margin-bottom: 5px !important;
    background: #fff;
}

.roleWidth>label {
    display: inline-block;
    width: 210px;
    margin-left: 0 !important;
    line-height: 30px;
}

.roleWidth>label:last-child {
    margin-bottom: 25px !important;
}

.showTabNav {
    background: #fff;
    .showtable_details {
        thead {
            th {
                background: #fafafa;
                height: 54px;
            }
        }
        td:first-child,
        th {
            padding-left: 16px;
        }
    }
    .showTablelist {
        table {
            th {
                text-align: left !important;
                padding-left: 16px;
            }
            tr>td {
                display: inline-block;
                width: 200px;
                margin-left: 0 !important;
                line-height: 30px;
            }
        }
    }
}

.buyServerCloudConfirm {
    .renewConfirm .ant-card-body .confirmBtn {
        width: none;
    }
}

.formNzRequired:before {
    display: inline-block;
    margin-right: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d;
}

.addBackendClass {
    .ant-table-tbody>tr>td {
        padding: 16px 0 16px 16px;
    }
}

.alain-default__fixed .reuse-tab {
    margin: 0;
    width: 60%;
    border-bottom: 0;
    margin-left: 50px;
    top: 0;
    z-index: 0;
    padding-bottom: 7px;
    .ant-tabs-nav .ant-tabs-tab {
        margin-right: 15px;
    }
    .ant-tabs-nav .ant-tabs-tab .name {
        padding: 8px;
    }
    .ant-tabs-nav .ant-tabs-tab .op {
        right: -8px;
    }
}

.alain-default__fixed .alain-default__content {
    margin-top: 38px;
}

.alain-default__progress-bar {
    height: 2px;
    margin-top: -39px;
}

.alain-default__fixed .alain-default__aside {
    z-index: 99;
}

.st .ant-table-pagination.ant-pagination {
    margin-right: 20px;
}

.addBackendClass {
    .ant-table-tbody>tr>td {
        padding: 16px 0 16px 16px;
    }
}

.shareFormLineHeight {
    .ant-form-item {
        margin-bottom: 8px;
    }
}

.alter_message {
    .ant-alert-description {
        font-size: 13px;
        line-height: 17px;
    }
}

.routerMargin .payInfos p.shouldPay {
    height: 70px;
    line-height: 70px;
}

.routerMargin .payInfos p.shouldPay:last-child {
    margin-bottom: 10px;
}

.elasticInfos p.shouldPay {
    height: 50px;
    line-height: 50px;
}

.elasticInfos p.shouldPay b {
    display: inline-block;
    width: 80px;
    font-weight: normal;
    text-align: right;
}

.cloud-server-parameters {
    font-size: 12px;
    line-height: 16px;
}

.errorSpan {
    color: #f5222d;
}

.alain-default__nav-item i {
    color: rgba(0, 0, 0, 0.65);
}

.alain-default__nav>li.hidden-mobile {
    margin: 0 5px;
    i {
        font-size: 18px;
    }
}

.configUl {
    padding-left: 0;
    margin-bottom: 0;
    li {
        list-style: none;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        span {
            width: auto;
            display: inline-block;
            text-align: right;
            margin-right: 5px;
        }
    }
}

.bills {
    .ant-card-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
    .ant-card-head {
        padding-left: 14px;
    }
}

.billsTop {
    .ant-card-body {
        padding-left: 10px;
        padding-bottom: 10px;
    }
    p>span {
        font-weight: bold;
        color: #000;
    }
}

.alertPreload {
    .ant-alert-description {
        p {
            font-size: 12px;
            margin-bottom: 0;
        }
    }
}

.notificationRow {
    border: 1px solid #ddd;
    .left {
        border-right: 1px solid #ddd;
    }
}

.notificationUl {
    height: 600px;
    overflow-y: scroll;
    padding: 0;
    margin-bottom: 0;
    li {
        list-style: none;
        border-bottom: 1px dotted #ddd;
        padding: 4px 20px;
    }
    .title {
        font-weight: normal;
        font-size: 14px;
        color: #555;
        margin-left: 10px;
    }
    .times {
        font-style: italic;
        display: block;
        color: #999;
        font-size: 10.2px;
        margin-bottom: 10px;
    }
    .toWeight {
        font-weight: bolder;
    }
}

.addNotifiBg {
    background: #f8f8f8;
}

.notice-icon .ant-list .ant-list-item .ant-list-item-meta-title {
    margin-bottom: 0;
}
#person_forms_css .ant-select-arrow .ant-select-arrow-icon{
    font-size: 12px;
}
#_market:hover {
    border-color: #66afe9;
}

.market-table {
    p {
        margin: 0;
    }
    .vendor {
        font-size: 12px;
        color: gray;
    }
}

.toolbarMt10 {
    margin-top: -10px;
}

.marketSearch {
    display: flex;
    span {
        height: 32px;
        line-height: 32px;
        margin-right: 5px;
    }
    input {
        width: 200px;
    }
}

.hotline {
    font-family: 'SourceHanSansCN-Normal';
    font-size: 12px;
    color: #F5A623;
    letter-spacing: 0;
    line-height: 18px;
    padding: 10px 10px 0 10px;
}

.domain {
    padding: 0 25px 25px 25px;
    h3 {
        font-size: 24px;
        font-family: "微软雅黑", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        a {
            float: right;
            font-size: 14px;
            line-height: 26px;
            color: #428bca;
            text-decoration: none;
            cursor: pointer;
        }
    }
    .domain-box {
        .domain-box-top {
            color: #fff;
            background-color: #09aee6;
            padding: 10px 15px;
            border-bottom: 1px solid transparent;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
        }
        .domain-box-mid {
            padding-top: 10px;
            background: #f5f5f5;
            margin-bottom: 20px;
            border: 1px solid transparent;
            border-radius: 4px;
            .domain-input {
                display: flex;
                justify-content: center;
                input {
                    min-width: 380px;
                    max-width: 500px;
                    margin-right: 5px;
                }
                nz-select {
                    margin-right: 5px;
                }
            }
            .domain-tips {
                width: 50%;
                text-align: left;
                padding-bottom: 10px;
                padding-top: 10px;
                color: #8a6d3b;
                background-color: #fcf8e3;
                border-color: #faebcc;
                padding: 15px;
                margin-bottom: 20px;
                border: 1px solid transparent;
                border-radius: 4px;
                margin: 10px auto 20px;
            }
        }
    }
}
.networklabel{
  .ant-radio-group{
    label{
      margin-right:5px;
      margin-bottom: 5px;
      border-radius: 4px;
    }
    .ant-radio-button-wrapper{
      border-left: 1px solid #d9d9d9;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
      width: 0;
    }
    .ant-radio-button-wrapper-checked:first-child, .ant-radio-button-wrapper-checked{
      border-left: 1px solid #1890ff;
      box-shadow: none;
    }
  }
}
.domain-info {
    h3 {
        color: #fff;
        background-color: #09aee6;
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }
}

.alain-default__collapsed .sidebar-nav:not(.sidebar-nav__sub) .sidebar-nav__item > a {
    text-align: center;
    padding: 16px 0;
}
.config-show p{
    margin: 0;
}

/* Textcomplete CSS */
.dropdown-menu {
    background-color: white;
    border: 1px solid #ddd;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

    .dropdown-menu li {
    border-top: 1px solid #ddd;
    padding: 2px 5px;
    margin: 0;
}

    .dropdown-menu li:first-child {
    border-top: none;
}

    .dropdown-menu li:hover,
    .dropdown-menu .active {
    background-color: #EEF6FF;
}

    .dropdown-menu a {
    height: 18px;
    text-decoration: none;
    color: #555;
}

    .dropdown-menu a:hover {
    cursor: pointer;
}

    .dropdown-menu img {
    vertical-align: text-top;
    height: 18px;
}

    .editor {
    margin: 1em 0 1em;
}

.textcomplete-header, .textcomplete-footer {
    display: none;
}
/* Textcomplete CSS End*/
.confirmWarning{
  div{
    height: 50px;
    line-height: 33px;
    text-align: center;
    span{
      font-size: 14px;
    }
  }
}
.beyond-processing{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.user-phone{
  display:flex;
}

.showSolid {
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}
.remove_internal_padding .ant-select-selection-selected-value {
    padding-right: 0px;
}
.edit_cdn_role{
    .ant-btn:active, .ant-btn:focus {
        color: rgba(0, 0, 0, 0.65);
        background-color: #fff;
        border-color: #d9d9d9;
    }
    .ant-btn:hover {
        color: #40a9ff;
        background-color: #fff;
        border-color: #40a9ff;
    }
} 

@{reuse-tab-prefix} {
  .ant-tabs-nav .ant-tabs-tab {
    @{reuse-tab-prefix}__op {
      display: none;
      position: absolute;
      top: 14px;
      right: -3px;
      margin: 0;
      font-size: 10px;
    }
    &:hover {
      @{reuse-tab-prefix}__op {
        display: block;
      }
    }
  }
}
.cal_flex .cal_center .cloudServer .cloudServer-left{
    margin-right: 0;
}
.ant-btn__block {
    display: block!important;
    width: 100%;
}

// 适配小屏
@media screen and (max-width: 768px) {
    .alain-default__collapsed .content {
        margin-left: 69px;
        width: 975px;
    }
    .alain-default__fixed .alain-default__content{
        width: 975px;
        transition: none;
    }
    .alain-default__collapsed .alain-default__aside{
        width: 64px;
        transform: none;
    }
    
    .alain-default__collapsed .sidebar-nav__sub{
        display: none !important;
    }
    .alain-default__collapsed .sidebar-nav:not(.sidebar-nav__sub) .sidebar-nav__item{
        border: none;
    }
    .alain-default__collapsed .sidebar-nav:not(.sidebar-nav__sub) .sidebar-nav__item-link{
        justify-content: center;
    }
    .alain-default__collapsed .sidebar-nav:not(.sidebar-nav__sub) .sidebar-nav__item-link .sidebar-nav__item-icon{
        margin-right: 0;
        font-size: 18px;
    }
    .alain-default__collapsed .sidebar-nav:not(.sidebar-nav__sub) .sidebar-nav__item-link .sidebar-nav__item-text{
        opacity: 0;
        display: none;
    }
    .alain-default__collapsed .sidebar-nav__sub-arrow{
        display: none;
    }
    

}
@media (max-width: 767px){
    .hidden-mobile{
        display: block !important;
    }
    .ant-table-tbody > tr > td{
        display: table-cell;
    }
    .ant-table-tbody > tr > td.ng-star-inserted{
        text-align: center !important;
    }
    .ant-table-tbody > tr > td >.ant-table-rep__title{
        width: 93px;
        text-align:center;
    }
    .ant-checkbox-inner{
        width: 25px;
        height: 25px;
    }
    .ant-checkbox-inner:after{
        position: absolute;
        left: 7.5px;
        top: 6px;
    }
}
@media (max-width: 575px){
    .hidden-xs{
        display: block !important;
    }
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters, .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters {
    padding-right: 0px!important;
}
.ant-table-tbody>tr>td, .ant-table-thead>tr>th {
    padding: 8px;
    word-break: break-word;
    -ms-word-break: break-all;
}

.ant-btn {
    padding: 0 10px;
}
